import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CustomLoading from '../../../util_components/custom_loading';
import { image_error_replace, session_type } from '../../../../util_functions';
import { time_format, day_format_year, tickImageUrl, closeImageUrl } from '../../../../constants';
import Button from '../../../util_components/button';
import * as actions from '../../../../redux/action_creators';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';
import Paginator from '../../../util_components/paginator';
import './sess.css';

class UserSessions extends Component {
  state = {
    loading: true,
    input_error: false,
    change_teacher_id: '',
    sessions_list: [],
    is_future: true,
    btn_toggled: false,
    page_number: 1,
    has_next: 0,
    page_limit: 0
  };

  componentDidMount() {
    this.get_url_details();
  }

  componentDidUpdate(newProps) {
    if (
      this.props.location.pathname !== newProps.location.pathname ||
      this.props.location.search !== newProps.location.search
    ) {
      this.get_url_details();
    }
  }

  changePage = (page) => {
    window.location.replace(`/pastsessions/backpain?page=${page}`);
  };

  get_url_details = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const uuid = url.searchParams.get('id');
    const user_type = url.searchParams.get('user_type');
    const user_name = url.searchParams.get('name');
    const search_page_number = url.searchParams.get('page');
    const page_number =
      !!search_page_number || search_page_number >= 1 ? parseInt(search_page_number) : 1;
    let is_future = true;
    if (window.location.pathname.indexOf('past') !== -1) {
      is_future = false;
    }
    this.setState({ uuid, user_type, user_name, is_future, page_number }, () => {
      this.load_details();
    });
  };

  go_to_sessions = (id, type) => {
    let search = `?id=${id}`;
    let pathname = '/sessiondetails';
    if (
      type === 'GROUP_SESSION' ||
      type === 'GROUP_SESSION_STUDENT' ||
      type === 'YOGA_WITH_FRIENDS_SESSION' ||
      type === 'YOGA_WITH_FRIENDS_SESSION_STUDENT'
    ) {
      search = `?session_uuid=${id}`;
      if (this.state.user_type === 'STUDENT') {
        search = `${search}&student_uuid=${this.state.uuid}&student_name=${this.state.user_name}`;
      }
      pathname = '/groupsessiondetails';
    }
    this.props.history.push({ pathname, search });
  };

  get_session_url = (id, type) => {
    let search = `?id=${id}`;
    let pathname = '/sessiondetails';
    if (
      type === 'GROUP_SESSION' ||
      type === 'GROUP_SESSION_STUDENT' ||
      type === 'YOGA_WITH_FRIENDS_SESSION' ||
      type === 'YOGA_WITH_FRIENDS_SESSION_STUDENT'
    ) {
      search = `?session_uuid=${id}`;
      if (this.state.user_type === 'STUDENT') {
        search = `${search}&student_uuid=${this.state.uuid}&student_name=${this.state.user_name}`;
      }
      pathname = '/groupsessiondetails';
    }
    return pathname + search;
  };

  load_details = () => {
    const url = get_api_url('get_all_sessions_of_student_or_teacher');
    const payload = {
      timezone: new Date().getTimezoneOffset(),
      user_uuid: this.state.uuid,
      user_type: this.state.user_type,
      is_future: this.state.is_future ? '1' : '0',
      page_number: this.state.page_number
    };
    this.setState({ loading: true }, () => {
      post_api(url, payload, true).then((result) => {
        if (this.state.user_type === 'TEACHER') {
          this.setState(
            { sessions_list: result.data.sessions.result, has_next: result.data.sessions.has_next },
            () => this.setState({ loading: false })
          );
        } else {
          this.setState(
            { sessions_list: result.data.sessions, has_next: result.data.has_next },
            () => this.setState({ loading: false })
          );
        }
      });
    });
  };

  go_back = () => {
    if (!this.state.is_future & this.state.btn_toggled) {
      this.setState({ is_future: true, btn_toggled: false });
    } else {
      this.props.history.goBack();
    }
  };

  open_session_viewer = (session_uuid) => {
    const search = `?session_uuid=${session_uuid}`;
    this.props.history.push({
      pathname: '/session_replay',
      search
    });
  };

  close_video = () => this.setState({ show_video: false });

  open_video = (url) =>
    this.setState({ video_url: url }, () => this.setState({ show_video: true }));

  render_sessions = () => {
    const sessions_list = [...this.state.sessions_list];
    const { page_number, page_limit } = this.state;
    const { access_type } = this.props;
    return (
      <div className="cus-table">
        <div className="ct-row ct-h">
          <div className="ct-col">{this.state.user_type === 'STUDENT' ? 'Teacher' : 'Student'}</div>
          <div className="ct-col ct-hf">Live</div>
          <div className="ct-col">Session Time</div>
          <div className="ct-col">Booked BY</div>
          <div className="ct-col">Status</div>
          <div className="ct-col ct-hf">Session</div>
          {this.state.user_type === 'STUDENT' ||
          (this.state.user_type === 'TEACHER' && !this.state.is_future) ? (
            <>
              <div className="ct-col ct-hf">Replay</div>
              <div className="ct-col ct-hf">Rating</div>
            </>
          ) : null}
          {access_type !== 'CONSULT_CONSULTANT' ? (
            <div className="ct-col ct-hf">Session Details</div>
          ) : null}
        </div>
        {sessions_list.map((session, index) => {
          const rating =
            session.status !== 'FINISHED' || session.student_rating === 0
              ? '--'
              : session.student_rating;
          const rating_color =
            session.student_rating > 3 ? 'green' : session.student_rating > 0 ? 'red' : 'auto';
          let profile_url;
          if (this.state.user_type === 'TEACHER') {
            profile_url = `/studentprofile?id=${session.student_uuid}`;
          } else {
            profile_url = `/teacherprofile?id=${session.teacher_uuid}`;
          }
          const cancelled_status = [
            'CANCELLED',
            'CANCELLED_BY_TEACHER',
            'CANCELLED_BY_STUDENT',
            'NO_SHOW_CANCELLATION_1HOUR',
            'TEACHER_NO_SHOW',
            'STUDENT_NO_SHOW',
            'STUDENT_AND_TEACHER_NO_SHOW',
            'JOINED_BUT_CANCELLED',
            'NO_SHOW_WITH_RECORDING'
          ];
          const status_style =
            cancelled_status.indexOf(session.status) === -1
              ? { color: '#016601' }
              : { color: '#ED4D4D' };
          const is_group =
            session.type === 'GROUP_SESSION' || session.type === 'GROUP_SESSION_STUDENT';
          let created_href = '';
          if (!!session.session_created_by_uuid && session.session_created_by_type == 'teacher') {
            created_href = `/teacherprofile?id=${session.session_created_by_uuid}`;
          } else if (
            !!session.session_created_by_uuid &&
            session.session_created_by_type == 'student'
          ) {
            created_href = `/studentprofile?id=${session.session_created_by_uuid}`;
          }
          return (
            <div className="ct-row" key={index + session.session_uuid}>
              <div className="ct-col ct-sm-font">
                <a className="link-no-dec" href={profile_url}>
                  <div className="profile-img-name">
                    {this.state.user_type === 'TEACHER' ? (
                      <img src={session.avatar_medium} alt="i" onError={image_error_replace} />
                    ) : (
                      <img
                        src={session.teacher_profile_photo}
                        alt="i"
                        onError={image_error_replace}
                      />
                    )}
                    {this.state.user_type === 'STUDENT'
                      ? session.teacher_name
                      : session.student_name}
                  </div>
                  {is_group ? <div>{`(${session.group_session_name})`}</div> : null}
                </a>
              </div>
              <div className="ct-col ct-hf">
                {session.is_live === 1 ? (
                  <div className="led-green" />
                ) : (
                  <div className="led-grey" />
                )}
              </div>
              <div className="ct-col ct-sm-font">
                <div>
                  {moment(session.epoch_start_time)
                    .tz(this.props.iana_timezone)
                    .format(day_format_year)}
                </div>
                <div>
                  {moment(session.epoch_start_time)
                    .tz(this.props.iana_timezone)
                    .format(time_format)}
                </div>
                <div>{`(${session.duration} mins)`}</div>
              </div>
              <div className="ct-col ct-sm-font">
                <a href={created_href} target="_blank" rel="noopener noreferrer">
                  <div>{session?.session_created_by_name}</div>
                  <div>{`(${session?.session_created_by_type})`}</div>
                </a>
              </div>
              <div className="ct-col ct-bw ct-sm-font" style={status_style}>
                {session.status}
                {session.status === 'CANCELLED' &&
                !!session.can_diff_hrs &&
                session.can_diff_hrs < 12 ? (
                  <span style={{ display: 'block' }}>(Less than 12 Hrs)</span>
                ) : null}
              </div>
              <div className="ct-col ct-si ct-hf ct-sm-font">
                <div>{session_type(session.type)}</div>
                <div>
                  {session.is_recurring === 1 ? (
                    <img
                      src="https://images.myyogateacher.com/icons/ic_repeat@2x.png"
                      alt="ic_rep"
                    />
                  ) : null}
                </div>
              </div>
              {this.state.user_type === 'STUDENT' ||
              (this.state.user_type === 'TEACHER' && !this.state.is_future) ? (
                <>
                  <div className="ct-col ct-si ct-hf">
                    {session.status === 'FINISHED' ? (
                      session.replay_url ? (
                        <img src={tickImageUrl} alt="av" />
                      ) : (
                        <img src={closeImageUrl} alt="nav" />
                      )
                    ) : (
                      <img src={closeImageUrl} alt="nav" />
                    )}
                  </div>
                  <div className="ct-col ct-hf" style={{ fontSize: '16px', color: rating_color }}>
                    {rating}
                  </div>
                </>
              ) : null}
              {access_type !== 'CONSULT_CONSULTANT' ? (
                <div className="ct-col ct-hf">
                  <a
                    href={this.get_session_url(session.session_uuid, session.type)}
                    className="ct-small-btn link-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Details
                  </a>
                </div>
              ) : null}
              <Paginator
                curr_page={page_number}
                page_limit={page_limit}
                pageChange={this.changePage}
              />
            </div>
          );
        })}
      </div>
    );
  };

  toggle_is_future = () => {
    // this.setState({ is_future: !this.state.is_future, btn_toggled: true }, () => this.load_details());
    let pathname = '/usersessions/future';
    if (this.state.is_future) {
      pathname = '/usersessions/past';
    }
    this.props.history.push({ pathname, search: window.location.search });
  };

  open_repeat_sessions = () => {
    const search = `?uuid=${this.state.uuid}`;
    this.props.history.push({
      pathname: '/repeat_sessions',
      search
    });
  };

  open_teacher_repeat_sessions = () => {
    const search = `?uuid=${this.state.uuid}`;
    this.props.history.push({
      pathname: '/repeat_sessions/teacher',
      search
    });
  };

  get_search = (is_next) => {
    const { uuid, user_type, user_name, page_number } = this.state;
    let search = `id=${uuid}`;
    if (user_type) search += `&user_type=${user_type}`;
    if (is_next) search += `&page=${page_number + 1}`;
    if (!is_next) search += `&page=${page_number - 1}`;
    if (user_name) search += `&name=${encodeURI(user_name)}`;
    return search;
  };

  go_prev_page = () => {
    const search = this.get_search(false);
    this.props.history.push({
      pathname: window.location.pathname,
      search
    });
  };

  go_next_page = () => {
    const search = this.get_search(true);
    this.props.history.push({
      pathname: window.location.pathname,
      search
    });
  };

  render_page = () => {
    const { page_number, has_next } = this.state;
    return (
      <div className="user-sess-page">
        <img
          className={page_number <= 1 ? 'user-sess-page-dis' : ''}
          src="https://images.myyogateacher.com/website/home/arrow_back.png"
          alt="arr"
          onClick={this.go_prev_page}
        />
        <img
          className={`user-sess-page-rev ${has_next === 0 ? 'user-sess-page-dis' : ''}`}
          src="https://images.myyogateacher.com/website/home/arrow_back.png"
          alt="arr"
          onClick={this.go_next_page}
        />
      </div>
    );
  };

  render_function = () => {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    let user_name = '';
    try {
      user_name = this.state.user_name.toUpperCase();
    } catch (e) {}
    const sessions_str =
      this.state.user_type === 'STUDENT'
        ? 'Sessions'
        : this.state.is_future
          ? 'Future sessions'
          : 'Past sessions';
    return (
      <div className="common-page-container">
        {this.props.zoom_signature_status === 'loading' ? <CustomLoading /> : null}
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.go_back}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">
            <span style={{ color: 'rgba(0,0,0,0.5)' }}>
              {sessions_str} of {this.state.user_type}{' '}
              <span style={{ color: 'black', fontSize: '18px' }}>{user_name}</span>
            </span>
          </div>
        </div>
        <div>
          {/* {this.state.user_type !== 'STUDENT' ?  : null} */}
          <Button
            onClick={this.toggle_is_future}
          >{`${this.state.is_future ? 'Past' : 'Future'} Sessions`}</Button>
          {this.state.user_type === 'STUDENT' && !!this.state.uuid ? (
            <Button onClick={this.open_repeat_sessions}>Repeat sessions</Button>
          ) : null}
          {this.state.user_type === 'TEACHER' && !!this.state.uuid ? (
            <Button onClick={this.open_teacher_repeat_sessions}>Repeat sessions</Button>
          ) : null}
        </div>
        {this.render_sessions()}
        {this.render_page()}
      </div>
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>User Sessions</title>
        </Helmet>
        {this.render_function()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  zoom_signature_status: state.loading.zoom_signature_status,
  iana_timezone: state.home.iana_timezone,
  access_type: state.home.access_type
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserSessions);
